'use client'

import { FormattedMessage } from "react-intl";
import Image from "next/image";
import Link from "next/link";
import { FormProvider, useForm } from "react-hook-form";
import { FormInput } from "@/app/components/inputs/FormInput";
import { useState } from "react";
import { signIn } from "next-auth/react";
import styles from "@/app/styles/page/login/login-page.module.scss";
import { useSearchParams } from "next/navigation";

const LoginForm = () => {
    const methods = useForm({
        defaultValues: {
            username: '',
            password: '',
        }
    })
    const [passwordShown, setPasswordShown] = useState(false);
    const searchParams = useSearchParams();

    const hasError = searchParams.get('error') || null;

    const onSubmit = async (data) => {
        await signIn('credentials', {
            username: data.username,
            password: data.password,
            redirect: true,
            callbackUrl: '/'
        })

    }
    return (
        <FormProvider {...methods}>
            {hasError && <p>{"Pogrešno korisničko ime ili lozinka"}</p>}
            <form onSubmit={methods.handleSubmit(onSubmit)} className="formStyle">
                <div className="inputContainer inputWithImage">
                    <FormattedMessage id={'common.form.email'}>
                        {(message) =>
                            <FormInput
                                name={'username'}
                                styleLabel={''}
                                label={''}
                                type={'email'}
                                styleInput={''}
                                placeholder={message}
                                registerOptions={{
                                    required: true,
                                }}
                                error={methods.formState.errors?.username}
                            />
                        }
                    </FormattedMessage>
                    <Image
                        src={'/email-2.png'}
                        alt="Email"
                        width={14}
                        height={12}
                        className="inputImage"
                    />
                </div>
                <div className="inputContainer inputWithImage">
                    <FormattedMessage id={'common.form.password'}>
                        {(message) =>
                            <FormInput
                                name={'password'}
                                styleLabel={''}
                                label={''}
                                type={passwordShown ? 'text' : 'password'}
                                styleInput={''}
                                placeholder={message}
                                registerOptions={{
                                    required: true,
                                }}
                                error={methods.formState.errors?.password}
                            />
                        }
                    </FormattedMessage>
                    <Image
                        src={'/eye-open.png'}
                        alt="Eye open"
                        width={17}
                        height={13}
                        className="inputImage"
                        onClick={() => setPasswordShown(!passwordShown)}
                    />
                </div>
                <div className="form-links-details">
                    <label className="form-links" htmlFor="login-checkbox">
                        <input
                            id="login-checkbox"
                            name="login-checkbox"
                            type="checkbox"
                            className="checkboxInput"
                        />
                        <FormattedMessage id={'common.form.rememberMe'} />
                    </label>
                    <Link className="form-links" href={"/zaboravljena-lozinka"}>
                        <FormattedMessage id={'common.form.forgotPassword'} />
                    </Link>
                </div>
                <div className="buttonContainer">
                    <button type="submit" className={`${styles.submitBtn} btnGlobal`}
                        disabled={methods.formState.isSubmitting}
                    ><FormattedMessage id={'pages.login.send'} /></button>
                </div>
            </form>
        </FormProvider>
    )
}

export default LoginForm