import {useFormContext} from 'react-hook-form';

export const FormInput = ({
                              name,
                              styleLabel,
                              label,
                              type,
                              styleInput,
                              placeholder,
                              registerOptions,
                              error,
                          }) => {
    const {register} = useFormContext();
    return (
        <>
            <label htmlFor={name} className={styleLabel}>
                {label}
            </label>
            <input
                id={name}
                type={type}
                className={styleInput}
                placeholder={placeholder}
                {...register(name, registerOptions)}
            />
            {error &&
                <span className="">{error.type === 'required' ? "This field is required" : error[name].message}</span>}
        </>
    );
};
